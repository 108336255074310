import './home.css';
import Footer from '../component/footer.js';
import Video from '../component/video.js';

function Page() {
  return (
    <div className='body'>
        <div><Video/></div>
        <div><Footer/></div>
        <img className='logoButton' onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}} src={require('../resources/image/DybvikCoaching.png')} alt='Dybvik coaching business logo' />
    </div>
  );
}

export default Page;
