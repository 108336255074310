import './footer.css';

function Component() {
  return (
    <div className='footerBody'>
      <div className='footerBottom'>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', paddingTop:'4px', paddingRight:'16px'}}>
          <img className='footerImage' src={require('../resources/image/DybvikCoachingGrayScale.png')} alt='grayscale of company logo' />
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', gap:'8px'}}>
            <text className='footerSectionText'>ORG NR: <a className='footerBottomLink' href='https://www.proff.no/selskap/dybvik-coaching/mauseidv%C3%A5g/skoler-og-undervisning/IFFUSWD10KR'>933 624 013</a></text>
            <text className='footerRightsText'>© 2024 Dybvik Coaching. Alle rettigheter reservert.</text>
          </div>
        </div>
        <div className='footerContactContainer'>
          <div style={{display:'flex', flexDirection:'column'}}>
            <text className='footerSectionText'>KONTAKTINFORMASJON</text>
            <a className='footerBottomLink' href='mailto:dybvikcoaching@gmail.com'>EPOST: DYBVIKCOACHING@GMAIL.COM</a>
            <a className='footerBottomLink' href='tel:+4746747778'>TELEFON: +47 46747778</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
