import './form.css';
import { useState } from 'react';
import Axios from 'axios';

function Component() {
    const [showNextButton, setShowNextButton] = useState(false);
    const [showSendButton, setShowSendButton] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [goal, setGoal] = useState(null);
    const [sex, setSex] = useState(null);
    const [age, setAge] = useState(null);
    const [about, setAbout] = useState('');
    const [available, setAvailable] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [formTitle, setFormTitle] = useState("Hva er målet ditt?");
    const [validEmail, setValidEmail] = useState(null);
    const [validName, setValidName] = useState(null);
    //const [validPhone, setValidPhone] = useState(null);
    const [avaitingResponse, setAvaitingResponse] = useState(false);

    const goToQuestion = (qn) => {
        switch (qn) {
            case 1:
                chooseQuestion1(null);
                break;
            case 2:
                chooseQuestion2(null);
                break;
            case 3:
                chooseQuestion3(null);
                break;
            case 4:
                chooseQuestion4(null);
                break;
            case 5:
                chooseQuestion5(null);
                break;
            default:
                break;
        }
    };

    const chooseQuestion1 = (event) => {
        setFormTitle("Hva ønsker du å oppnå?");
        setShowSendButton(false);
        setShowNextButton(false);
        setShowBackButton(false);
        setCurrentQuestion(1);
    };

    const chooseQuestion2 = (event) => {
        setFormTitle("Hva er kjønnet ditt?");
        setShowSendButton(false);
        setShowNextButton(false);
        setShowBackButton(true);
        setCurrentQuestion(2);
        if (event != null) {
            setGoal(event.target.value);
        }
    };

    const chooseQuestion3 = (event) => {
        setFormTitle("Hva er alderen din?");
        setShowSendButton(false);
        setShowNextButton(false);
        setShowBackButton(true);
        setCurrentQuestion(3);
        if (event != null) {
            setSex(event.target.value);
        }
    };

    const chooseQuestion4 = (event) => {
        setFormTitle("Fortell litt om deg selv");
        setShowSendButton(false);
        setShowNextButton(true);
        setShowBackButton(true);
        setCurrentQuestion(4);
        if (event != null) {
            setAge(event.target.value);
        }
    };

    const chooseQuestion5 = (event) => {
        setFormTitle("Når ønsker du å bli kontaktet?");
        setShowSendButton(false);
        setShowNextButton(false);
        setShowBackButton(true);
        setCurrentQuestion(5);
    };

    const handleTextAreaChange = (event) => {
        setAbout(event.target.value);
    };

    const handleNameInputChange = (event) => {
        setName(event.target.value);
        setValidName(verifyName());
    };

    const handleEmailInputChange = (event) => {
        setEmail(event.target.value);
        if(validEmail === false) {
            setValidEmail(verifyEmail());
        }
    };

    const handlePhoneInputChange = (event) => {
        let inputValue = event.target.value;
        if (/^[+\d]*$/.test(inputValue)) {
            setPhone(inputValue);
        }
    };

    const chooseQuestion6 = (event) => {
        setFormTitle("Kontakt informasjon");
        setShowSendButton(true);
        setShowNextButton(false);
        setShowBackButton(true);
        setCurrentQuestion(6);
        if (event != null) {
            setAvailable(event.target.value);
        }
    };

    const verifyName = () => {
        return (name !== null && name !== "")
    }

    const verifyEmail = () => {
        const emailRegex = /^(?=.{1,64}@)(?!.*@-.*)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;
        return (email != null && emailRegex.test(email))
    }

    const sendForm = (goal, sex, age, about, available, name, email, phone) => {
        const isValidName = verifyName();
        const isValidEmail = verifyEmail();

        setValidName(verifyName());
        setValidEmail(verifyEmail());

        if(isValidName && isValidEmail) {
            setAvaitingResponse(true);
            let data = {
                goal: goal,
                sex: sex,
                age: age,
                about: about,
                available: available,
                name: name,
                email: email,
                phone: phone
            };
            console.log(data);
            Axios.post("https://dybvikcoaching.no/api/sendform", data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    setAvaitingResponse(false);
                    if(response.status === 200) {
                        setCurrentQuestion("SUCCESS");
                        setFormTitle("Skjemaet er sendt!");
                        setShowSendButton(false);
                        setShowBackButton(false);
                    }
                })
                .catch((error) => { console.error(error); setAvaitingResponse(false); alert("Beklager noe gikk galt!\nPrøv igjen, hvis problemet vedvarer du kan kontakte direkte via epost: dybvikcoaching@gmail.com") });
        } else {
            console.log(validName);
            console.log(validEmail);
        }
    };

    const renderQuestionContent = () => {
        switch (currentQuestion) {
            case 1:
                return (
                    <div className='formContent'>
                        <div className='formButtons' >
                            <button className='formButton' onClick={chooseQuestion2} value={"Vekttap"}>Vekttap</button>
                            <button className='formButton' onClick={chooseQuestion2} value={"Muskelbygging"}>Muskelbygging</button>
                        </div>
                        <div className='formButtons'>
                            <button className='formButton' onClick={chooseQuestion2} value={"Livsstilendring"}>Livsstilendring</button>
                            <button className='formButton' onClick={chooseQuestion2} value={"Annet"}>Annet</button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='formContent'>
                        <div className='formButtons'>
                            <button className='formButton' onClick={chooseQuestion3} value={"Mann"}>Mann</button>
                            <button className='formButton' onClick={chooseQuestion3} value={"Kvinne"}>Kvinne</button>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className='formContent'>
                        <div className='formButtons' >
                            <button className='formButton' onClick={chooseQuestion4} value={"Under 18"}>Under 18</button>
                            <button className='formButton' onClick={chooseQuestion4} value={"18-30"}>18-30</button>
                        </div>
                        <div className='formButtons' >
                            <button className='formButton' onClick={chooseQuestion4} value={"30-45"}>30-45</button>
                            <button className='formButton' onClick={chooseQuestion4} value={"Over 45"}>Over 45</button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className='formContent'>
                        <textarea className='formTextArea' onChange={handleTextAreaChange} value={about} placeholder='Er det noe spesielt du ønsker å oppnå, eller er det noe du sliter med?'></textarea>
                    </div>
                );
            case 5:
                return (
                    <div className='formContent'>
                        <div className='formButtons' >
                            <button className='formButton' onClick={chooseQuestion6} value={"8-12"}>8-12</button>
                            <button className='formButton' onClick={chooseQuestion6} value={"12-15"}>12-15</button>
                        </div>
                        <div className='formButtons' >
                            <button className='formButton' onClick={chooseQuestion6} value={"15-18"}>15-18</button>
                            <button className='formButton' onClick={chooseQuestion6} value={"18-21"}>18-21</button>
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className='formContent'>
                        <div style={{display:'flex', flexDirection:'column', marginBottom:'10px'}}>
                            <h2 className='contactLableTitle' style={{ color: 'white', marginBottom: '0px', marginTop: '0px' }}>Fullt navn <span style={{color:'red'}}>*</span></h2>
                            <input onChange={handleNameInputChange} value={name} className={validName == null || validName ? 'contactInput' : 'contactInputInvalid'} placeholder='Fullt navn'></input>
                            {validName != null && !validName && <text className='invalidText'>Fyll inn navnet ditt</text>}
                        </div>
                        <div style={{display:'flex', flexDirection:'column', marginBottom:'10px'}}>
                            <h2 className='contactLableTitle' style={{ color: 'white', marginBottom: '0px', marginTop: '0px' }}>Epost <span style={{color:'red'}}>*</span></h2>
                            <input onChange={handleEmailInputChange} value={email} className={validEmail == null || validEmail ? 'contactInput' : 'contactInputInvalid'} placeholder='Epost'></input>
                            {validEmail != null && !validEmail && <text className='invalidText'>Ugyldig Epost</text>}
                        </div>
                        <div style={{display:'flex', flexDirection:'column', marginBottom:'10px'}}>
                            <h2 className='contactLableTitle' style={{ color: 'white', marginBottom: '0px', marginTop: '0px' }}>Telefon</h2>
                            <input onChange={handlePhoneInputChange} value={phone} className='contactInput' placeholder='Telefon'></input>
                        </div>
                    </div>
                );
            case "SUCCESS":
                return (
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <text className='successIcon'>✅</text>
                        <text className='successText'>Vi vil kontakte deg så snart som mulig via Epost, vennligst sjekk innboksen din. Takk!</text>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className='formContainer'>
            <h1 className='formTitle'>{formTitle}</h1>
            {renderQuestionContent()}
            <div className='formBottom'>
                {showBackButton && (<button className='backButton' onClick={() => { goToQuestion(currentQuestion - 1) }} >Tilbake</button>)}
                {showNextButton && (<button className='nextButton' onClick={() => { goToQuestion(currentQuestion + 1) }} >Neste</button>)}
                {showSendButton && (<button className='nextButton' onClick={() => {
                    sendForm(
                        goal,
                        sex,
                        age,
                        about,
                        available,
                        name,
                        email,
                        phone
                    )
                }}>{avaitingResponse ? (<img className='loadingGif' src={require('../resources/image/gifImages/loading.gif')} alt='loading.gif' />) : "Send"}</button>)}
            </div>
        </div>
    );
}

export default Component;
