import './video.css';
import Form from './form.js';

function Component() {

  return (
        <div className='videoBody'>
            <div className='test'>
                <div className='textContainer'>
                    <h1 className='header1'>Dybvik Coaching</h1>
                    <Form />
                </div>
                <img className='background-video' src={require('../resources/image/background2.png')} alt='' />
            </div>
            <div className='imageContainer'>
                <div className='imageFilm'></div>
                <img className='background-video' src={require('../resources/image/headshot.jpg')} alt='' />
            </div>
        </div>
  );
}

export default Component;
